<template>
  <vx-card title="Cash Bank Management">
    <div class="vx-row mb-2" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Deposit Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <date-range-picker style="min-height: 40px" class="w-full" ref="picker" opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :singleDatePicker="false" :timePicker="false"
            :timePicker24Hour="false" :showWeekNumbers="false" :showDropdowns="false" :autoApply="true"
            v-model="depositDate" :linkedCalendars="true">
            <template v-slot:input="picker" style="min-width: 350px">
              {{ globalDateFormat(picker.startDate) }} -
              {{ globalDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
          <vs-button class="ml-4" color="danger" icon-pack="feather" icon="icon-x" @click="
            () => {
              this.depositDate = { startDate: null, endDate: null }
            }
          "></vs-button>
          <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
        </div>
      </div>
    </div>
    <div class="vx-row mb-2" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Collection Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <date-range-picker style="min-height: 40px" class="w-full" ref="picker" opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :singleDatePicker="false" :timePicker="false"
            :timePicker24Hour="false" :showWeekNumbers="false" :showDropdowns="false" :autoApply="true"
            v-model="collectDate" :linkedCalendars="true">
            <template v-slot:input="picker" style="min-width: 350px">
              {{ globalDateFormat(picker.startDate) }} -
              {{ globalDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
          <vs-button class="ml-4" color="danger" icon-pack="feather" icon="icon-x" @click="
            () => {
              this.collectDate = { startDate: null, endDate: null }
            }
          "></vs-button>
          <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
        </div>
      </div>
    </div>
    <div class="vx-row mb-2" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Operating Unit / Territory</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect class="selectExample" v-model="operatingUnit" :options="optionOU" :multiple="true"
            :allow-empty="true" :group-select="false" :max-height="120" :limit="3" placeholder="Type to search"
            track-by="ID" label="Name">
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">
                  <template v-if="props.option.ID != 0">
                    ({{ props.option.Code }}), ({{
                        props.option.OperatingUnitTerritory.Territory.code
                    }})
                    {{ props.option.OperatingUnitTerritory.Territory.name }}
                  </template>
                  <template v-else>
                    ({{ props.option.Code }})
                  </template>
                </span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">

                  <template v-if="props.option.ID != 0">
                    ({{ props.option.Code }}), ({{
                        props.option.OperatingUnitTerritory.Territory.code
                    }})
                    {{ props.option.OperatingUnitTerritory.Territory.name }}
                  </template>
                  <template v-else>
                    ({{ props.option.Code }})
                  </template>
                </span>
              </div>
            </template>
          </multiselect>
        </div>
    </div>
    <div class="vx-row mb-2" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span></span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <vs-input  
          placeholder="filename" v-model="filename" class="w-full"
          />
          <vs-button v-if="!readyDownload" class="ml-4" color="primary" @click="getData()">Get Data</vs-button>
          <vs-button v-if="readyDownload" class="ml-4" color="primary" @click="startDownload()">Excel </vs-button>
          <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
        </div>
      </div>
    </div>
    
    <div class="vx-row mb-12">
      <div v-bind:class="[
        detail ? detailShow + 'md:w-1/4 w-full mb-base' : '',
        detailHide,
      ]">
       <vs-tabs>
      <vs-tab label="Report">
        <data-table
        :responseData="responseData"
        :propsParams="params"
        :header="header"
        @reloadDataFromChild="reloadData"
      >
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.cashBank"  :class="tr.class">
          <vs-td>
            {{ tr.TerritoryCode }}
          </vs-td>
          <vs-td>
            {{ tr.TerritoryName }}
          </vs-td>
          <vs-td>
            {{ tr.Code }}
          </vs-td>
          <vs-td>
            {{ globalDateFormat(tr.Date) }}
          </vs-td>
          <vs-td>
            {{ tr.DepositNumber }}
          </vs-td>
          <vs-td>
            {{ globalDateFormat(tr.DatePayment) }}
          </vs-td>
          <vs-td>
            {{tr.ReferenceCode }}
          </vs-td>
          <vs-td>
            {{methods[tr.Method]}}
          </vs-td>
          <vs-td>
            {{priceFormat(tr.DepositValue)}}<br>
          </vs-td>
          <vs-td>
           {{tr.Status}}
          </vs-td>
          <vs-td>
           {{tr.CoaInCode}}
          </vs-td>
          <vs-td>
           {{tr.CoaInName}}
          </vs-td>
          <vs-td>
           {{tr.UserName}}<br>
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
      </vs-tab>
      <vs-tab label="Export">
        <div class="tab-text">
          <data-table-export></data-table-export>
        </div>
      </vs-tab>
      
    </vs-tabs>
       
      </div>
      <transition name="detail-fade">
        <div v-if="detail" v-bind:class="[
          detail ? detailShow + 'md:w-3/4 w-full mb-base' : '',
          detailHide,
        ]">
          <vs-row>
            <vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="rigth" vs-align="rigth"
              vs-w="4">
              <vs-button class="ml-auto" size="small" v-on:click="handleClose" color="grey" icon-pack="feather"
                icon="icon-x-square">Close</vs-button>
            </vs-col>
            <vs-col class="vertical-divider" vs-offset="0" vs-type="flex" vs-justify="center" vs-align="center"
              vs-w="1">
              <vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather"
                icon="icon-x-square"></vs-button>
            </vs-col>
            <!-- form component -->
            <create @close="handleClose" />
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import DataTableExport from "./DataTableExport.vue";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import moment from 'moment'

export default {
  components: {
    DataTableExport,
    DateRangePicker
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },

      
      header: [
        {
          text: "Teritory Code",
          value: "teritory_id",
          // width: '5%'
        },
        {
          text: "Teritory Desc",
          value: "teritory_id",
        },
        {
          text: "Doc. No.",
          value: "code",
        },
        {
          text: "Deposit Date",
          value: "date",
        },
        {
          text: "Cash Deposit Slip No.",
          value: "deposit_number",
        },
        {
          text: "Collection Date",
          value: "date_payment",
        },
        {
          text: "Ref Code",
          value: "reference_code",
        },
        {
          text: "Method Management",
          value: "method",
        },
        {
          text: "Deposit Value",
          value: "deposit_value",
        },
        {
          text: "Doc Status",
          value: "deposit_value",
        },
        {
          text: "GL Account Code",
        },
        {
          text: "Operating Unit Bank",
        },
        {
          text: "User Name",
        }
      ],
      methods:['','Cash','Giro','Cheque','Transfer'],
      responseData: {},
      filename:'',
      readyDownload: false,
      firstLoad:true,
      optionOU: [],
      operatingUnit: [],
      operatingUnitText: '',
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      colorx: "red",
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      componentKey: 0,
      postingDate: {},
      depositDate: {
        startDate: null,
        endDate: null,
      },
      collectDate: {
        startDate: null,
        endDate: null,
      },
    };
  },
  mounted() {
    this.getOptionOU();
  },
  watch: {
    "depositDate"() {
      this.readyDownload = false
    },
    "collectDate"() {
      this.readyDownload = false
    },
    operatingUnit() {
      this.readyDownload = false
      console.log(this.operatingUnit)
      // const checkAll = this.operatingUnit.filter(v => {
      //   return v.ID == 0
      // })

      if (this.operatingUnit.length == 0) {
        this.operatingUnit.push({ ID: 0, Code: 'All', Name: 'All' })
        this.operatingUnitText = ''
        this.filename = 'Territory-All'
      } else {
        if (this.operatingUnit[this.operatingUnit.length - 1].ID == 0) {
          this.operatingUnit = { ID: 0, Code: 'All', Name: 'All' }
          this.operatingUnitText = ''
          this.filename = 'Territory-All'
        } else {
          const operatingUnit = this.operatingUnit.filter(v => {
            return v.ID != 0
          })
          if (this.operatingUnit.length > operatingUnit.length) {
            this.operatingUnit = operatingUnit
          }
          console.log(operatingUnit)
          const territory = []
          const name = []
          this.operatingUnit.map(v => {
            territory.push(v.OperatingUnitTerritory.TerritoryID)
            name.push(v.OperatingUnitTerritory.TerritoryName)
          })
          this.operatingUnitText = territory.join(",")
          this.filename = "Territory-" + name.join("_")
        }
      }
      // this.getBankForm(this.operatingUnit)
      // this.reloadData(this.params)
      // this.handleClose()
    },
  },
  methods: {
    handleClose() {
      this.detail = false;
      this.componentKey += 1;
      this.$refs.open.closeDetail()
    },
    handleOpenForm() {
      this.detail = true;
      this.status_detail = "form-create";
    },
    nameFile() {
      this.filename = ''
      if (this.operatingUnit.length == 0) {
        this.filename += 'Territory-All__'
      } else {
        console.log(this.operatingUnit,this.operatingUnit.length - 1,this.operatingUnit instanceof Array)
        // console.log(this.operatingUnit, '------------',this.operatingUnit[this.operatingUnit.length - 1].ID == 0)
        if (this.operatingUnit instanceof Array && this.operatingUnit[this.operatingUnit.length - 1].ID == 0) {
          this.filename += 'Territory-All__'
        } else if(this.operatingUnit.ID == 0) {
          this.filename += 'Territory-All__'
        } else {
          let name = []
          this.operatingUnit.map(v => {
            name.push(v.Code)
          })
          this.filename += "Territory-" + name.join("_") + "__"
          console.log(name)
        }
      }
      if (this.depositDate.startDate == null) {
        this.filename += "DepoD-All__"
      } else {
        this.filename += "DepoD-" + moment(this.depositDate.startDate ).format("YYYY-MM-DD")+"_"+moment(this.depositDate.endDate ).format("YYYY-MM-DD")+"__"
      }
      if (this.collectDate.startDate == null) {
        this.filename += "ColectD-All"
      } else {
        this.filename += "ColectD-" + moment(this.collectDate.startDate ).format("YYYY-MM-DD")+"_"+moment(this.collectDate.endDate ).format("YYYY-MM-DD")
      }
    },
    getData() {
      this.readyDownload = true
      this.firstLoad = false
      this.nameFile()
      this.reloadData(this.params)
    },
    
    reloadData(params) {
      if (this.firstLoad) {
        this.data = []
        this.responseData = {};
        this.responseData.length = 0;
        return 
      }
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.cashBank;
          this.responseData = r.data;
          this.responseData.length = r.data.cashBank.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http.get('/api/v1/cash-bank/report',{
            params: {
                // status: 5,
                search: params.search,
                length: params.length,
                page: params.page,
                order: params.sort,
                sort: params.order,
                territory_text:this.operatingUnitText,
                start_collect_date: this.collectDate.startDate  ? moment(this.collectDate.startDate ).format("YYYY-MM-DD"): null,
                end_collect_date: this.collectDate.endDate ? moment(this.collectDate.endDate ).format("YYYY-MM-DD"): null,
                start_deposit_date: this.depositDate.startDate ? moment(this.depositDate.startDate ).format("YYYY-MM-DD"): null,
                end_deposit_date:this.depositDate.endDate ? moment(this.depositDate.endDate ).format("YYYY-MM-DD"): null,

            }
        }).then((r) => {
          resolve(r)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    startDownload() {
      const download = this.download(this.params);
      download.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: r.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "success",
            title: "Success",
            text: r.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    download(params) {
      return new Promise((resolve, reject) => {
        this.$http.get('/api/v1/cash-bank/report/export',{
            params: {
                search: params.search,
                title: this.filename,
                length: params.length,
                page: params.page,
                order: params.sort,
                sort: params.order,
                start_collect_date: this.collectDate.startDate  ? moment(this.collectDate.startDate ).format("YYYY-MM-DD"): null,
                end_collect_date: this.collectDate.endDate ? moment(this.collectDate.endDate ).format("YYYY-MM-DD"): null,
                start_deposit_date: this.depositDate.startDate ? moment(this.depositDate.startDate ).format("YYYY-MM-DD"): null,
                end_deposit_date:this.depositDate.endDate ? moment(this.depositDate.endDate ).format("YYYY-MM-DD"): null,
            }
        }).then((r) => {
          resolve(r)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getOptionOU() {
      this.optionOU = []
      this.$http.get("/api/v1/master/operating-unit").then((resp) => {
        // this.optionOU = [...{ID:0,Code:'All',Name:'All'},resp.data.records];
        this.optionOU.push({ ID: 0, Code: 'All', Name: 'All' })
        resp.data.records.map(v => {
          this.optionOU.push(v)
        })
        this.filename = 'Territory-All__ColectD-All__DepoD-All'
        this.operatingUnit =[]
        this.operatingUnit.push[{ ID: 0, Code: 'All', Name: 'All' }];
      });
    },
  },
};
</script>
<style>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight>td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>